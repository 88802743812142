<template>
  <Typeform v-if="Object.keys(user_meta).length"/>
</template>

<script>
import Typeform from '@/components/OnboardingTypeform'
import { mapGetters } from 'vuex'

export default {
  name: 'OnboardingTypeform',
  components: {
    Typeform
  },
  computed: {
    ...mapGetters({
      user_meta: 'general/user_meta'
    })
  },  
}
</script>

<style>
</style>